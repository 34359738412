import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
} from "@material-ui/core";
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import api from '../../services/api';

const RegistradorPrecos = () => {
    // State variables for the form fields
    const [materias, setMaterias] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [fontes, setFontes] = useState([]);
    const [portos, setPortos] = useState([]);
    const [embalagens, setEmbalagems] = useState([
        { value: 'Big Bag', label: 'Big Bag' },
        { value: 'Granel', label: 'Granel' },
    ]);

    const [materiaSelecionada, setMateriaSelecionada] = useState(null);
    const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
    const [origem, setOrigem] = useState(null);
    const [destino, setDestino] = useState(null);
    const [data, setData] = useState(new Date().toISOString().split("T")[0]); // Default to today
    const [dataPagamento, setDataPagamento] = useState(new Date().toISOString().split("T")[0]); // Default to today
    const [fonteSelecionada, setFonteSelecionada] = useState(null);
    const [empresaSelecionada, SetEmpresaSelecionada] = useState(null);
    const [portoSelecionado, setPortoSelecionado] = useState(null);
    const [frete, setFrete] = useState(0);
    const [precoCompra, setPrecoCompra] = useState(0);
    const [dolar, setDolar] = useState(0);
    const [embalagem, setEmbalagem] = useState(null);
    const [volume, setVolume] = useState(0);
    const [jurosBRL, setJurosBRL] = useState(0);
    const [jurosUSD, setJurosUSD] = useState(0);
    const [atualizar, setAtualizar] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [isAssociado, setIsAssociado] = useState(false);
    const [associados, setAssociados] = useState([]);
    const [associado, setAssociado] = useState(null);

    useEffect(() => {
        fetchMyApi();
    }, [atualizar]);

    const fetchMyApi = async () => {
        try {
            const respose_dolar_hora = await api.get('/DolarApi');
            setDolar(respose_dolar_hora.data.toString());

            setCarregando(true);
            const token = await getToken();
            const headers = { headers: { 'x-api-key': token } };

            const responses = await Promise.all([
                api_amr.get('/return_indice_materias', headers),
                api_amr.get('/return_cidades_brasil', headers),
                api_amr.get('/retorna_empresa_fonte', headers),
                api_amr.get('/return_portos', headers),
                api_amr.get('/get_associados', headers),
            ]);

            const [responseMaterias, responseCidades, responseEmpresasFontes, responsePortos, responseAssociados] = responses;

            if (responseMaterias.status === 200) {
                setMaterias(responseMaterias.data.map(item => ({ value: item.id, label: item.descNome })));
            } else {
                alert('Ocorreu um erro ao retornar a lista de matérias');
            }

            if (responseCidades.status === 200) {
                setCidades(responseCidades.data.map(item => ({ value: item.id, label: `${item.cidade}/${item.UF}` })));
            } else {
                alert('Ocorreu um erro ao retornar a lista de cidades');
            }

            if (responseEmpresasFontes.status === 200) {
                setEmpresas(responseEmpresasFontes.data.empresas.map(item => ({ value: item.empresa, label: item.empresa })));
                setFontes(responseEmpresasFontes.data.fontes.map(item => ({ value: item.fonte_dados, label: item.fonte_dados })));
            } else {
                alert('Ocorreu um erro ao retornar a lista de empresas e fontes');
            }

            if (responsePortos.status === 200) {
                setPortos(responsePortos.data.map(item => ({ value: item.id, label: item.nome_porto })));
            } else {
                alert('Ocorreu um erro ao retornar a lista de portos');
            }

            if (responseAssociados.status === 200) {
                setAssociados(responseAssociados.data.map(item => ({ value: item.id_associado, label: item.name_associado })));
            } else {
                alert('Ocorreu um erro ao retornar a lista de Associados');
            }

            setCarregando(false);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setCarregando(false);
            alert('Ocorreu um erro ao buscar os dados.');
        }
    };

    const limparFormulario = () => {
        setMateriaSelecionada(null);
        setCidadeSelecionada(null);
        setOrigem(null);
        setDestino(null);
        setData(new Date().toISOString().split("T")[0]); // Reset to today
        setDataPagamento(new Date().toISOString().split("T")[0]); // Reset to today
        setFonteSelecionada(null);
        SetEmpresaSelecionada(null);
        setPortoSelecionado(null);
        setFrete(0);
        setPrecoCompra(0);
        setDolar(0);
        setEmbalagem(null);
        setVolume(0);
        setJurosBRL(0);
        setJurosUSD(0);
        setAssociado(null);
        setIsAssociado(false);
    };

    const handleAdicionaPreco = async () => {
        if (!materiaSelecionada || !cidadeSelecionada || !empresaSelecionada || !portoSelecionado || !embalagem || !precoCompra || (isAssociado && !associado)) {
            alert('Por favor, preencha todos os campos obrigatórios.');
            return;
        }

        setAtualizar(true);
        const response = await api_amr.post("/registra_preco_venda", {
            data: data,
            data_pagamento: dataPagamento,
            empresa: empresaSelecionada.label,
            id_porto: portoSelecionado.value,
            origem: cidadeSelecionada.value,
            destino: cidadeSelecionada.value,
            produto: materiaSelecionada.value,
            fonte_dados: fonteSelecionada ? fonteSelecionada.label : '',
            frete: frete,
            preco_compra: precoCompra,
            dolar: dolar,
            embalagem: embalagem.label,
            volume: volume,
            juros_brl: jurosBRL,
            juros_usd: jurosUSD,
            isAssociado: isAssociado,
            associado: associado,
        },
            { headers: { 'x-api-key': await getToken() } });

        if (response.data === 'OK') {
            limparFormulario();
            setAtualizar(false);
            alert('Preço registrado com sucesso');
        } else {
            setAtualizar(false);
            limparFormulario();
            alert('Ocorreu um erro, tente novamente');
        }
    };

    return (
        <form onSubmit={(e) => { e.preventDefault(); handleAdicionaPreco(); }}>
            {carregando && <CircularProgress />}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Matéria</InputLabel>
                        <Select
                            value={materiaSelecionada}
                            onChange={(e) => setMateriaSelecionada(e.target.value)}
                        >
                            {materias.map((materia) => (
                                <MenuItem key={materia.value} value={materia.value}>
                                    {materia.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Embalagem</InputLabel>
                        <Select
                            value={embalagem}
                            onChange={(e) => setEmbalagem(e.target.value)}
                        >
                            {embalagens.map((embalagem) => (
                                <MenuItem key={embalagem.value} value={embalagem.value}>
                                    {embalagem.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Porto</InputLabel>
                        <Select
                            value={portoSelecionado}
                            onChange={(e) => setPortoSelecionado(e.target.value)}
                        >
                            {portos.map((porto) => (
                                <MenuItem key={porto.value} value={porto.value}>
                                    {porto.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Cidade</InputLabel>
                        <Select
                            value={cidadeSelecionada}
                            onChange={(e) => setCidadeSelecionada(e.target.value)}
                        >
                            {cidades.map((cidade) => (
                                <MenuItem key={cidade.value} value={cidade.value}>
                                    {cidade.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAssociado}
                                onChange={(e) => setIsAssociado(e.target.checked)}
                            />
                        }
                        label="Associado"
                    />
                </Grid>

                {isAssociado ? (
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Associado</InputLabel>
                            <Select
                                value={associado}
                                onChange={(e) => setAssociado(e.target.value)}
                            >
                                {associados.map((associado) => (
                                    <MenuItem key={associado.value} value={associado.value}>
                                        {associado.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Fonte</InputLabel>
                            <Select
                                value={fonteSelecionada}
                                onChange={(e) => setFonteSelecionada(e.target.value)}
                            >
                                {fontes.map((fonte) => (
                                    <MenuItem key={fonte.value} value={fonte.value}>
                                        {fonte.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Empresa</InputLabel>
                        <Select
                            value={empresaSelecionada}
                            onChange={(e) => SetEmpresaSelecionada(e.target.value)}
                        >
                            {empresas.map((empresa) => (
                                <MenuItem key={empresa.value} value={empresa.value}>
                                    {empresa.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Volume TON"
                        type="number"
                        value={volume === 0 ? '' : volume}
                        onChange={(e) => setVolume(Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Frete R$"
                        type="number"
                        value={frete === 0 ? '' : frete}
                        onChange={(e) => setFrete(Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Preço Compra USD"
                        type="number"
                        value={precoCompra === 0 ? '' : precoCompra}
                        onChange={(e) => setPrecoCompra(Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Dólar"
                        type="number"
                        value={dolar === 0 ? '' : dolar}
                        onChange={(e) => setDolar(Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Data"
                        type="date"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Data de Pagamento"
                        type="date"
                        value={dataPagamento}
                        onChange={(e) => setDataPagamento(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Juros BRL"
                        type="number"
                        value={jurosBRL === 0 ? '' : jurosBRL}
                        onChange={(e) => setJurosBRL(Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label="Juros USD"
                        type="number"
                        value={jurosUSD === 0 ? '' : jurosUSD}
                        onChange={(e) => setJurosUSD(Number(e.target.value))}
                    />
                </Grid>


                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={atualizar}
                        fullWidth
                    >
                        {atualizar ? <CircularProgress size={24} /> : "Salvar"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default RegistradorPrecos;
