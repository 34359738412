import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  headerCell: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const RegistradorPrecos = () => {
  const classes = useStyles();

  // Estados
  const [materias, setMaterias] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [fontes, setFontes] = useState([]);
  const [portos, setPortos] = useState([]);
  const [embalagens] = useState([
    { value: 'Big Bag', label: 'Big Bag' },
    { value: 'Granel', label: 'Granel' },
  ]);
  const [data, setData] = useState(new Date().toISOString().split('T')[0]);
  const [dataPagamento, setDataPagamento] = useState(new Date().toISOString().split('T')[0]);
  const [materiaSelecionada, setMateriaSelecionada] = useState(null);
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const [fonteSelecionada, setFonteSelecionada] = useState('');
  const [portoSelecionado, setPortoSelecionado] = useState(null);
  const [frete, setFrete] = useState('');
  const [precoCompra, setPrecoCompra] = useState('');
  const [dolar, setDolar] = useState('');
  const [embalagem, setEmbalagem] = useState(null);
  const [volume, setVolume] = useState('');
  const [jurosBRL, setJurosBRL] = useState('');
  const [jurosUSD, setJurosUSD] = useState('');
  const [isAssociado, setIsAssociado] = useState(false);
  const [associados, setAssociados] = useState([]);
  const [associado, setAssociado] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [precos, setPrecos] = useState([]);
  const [filter, setFilter] = useState('');
  const [nomesMaterias, setNomesMaterias] = useState([]);
  const [escolhaMaterias, setEscolhaMaterias] = useState(true);

  // Formatação de moeda
  const formatCurrency = (value, currency) => {
    if (value == null || isNaN(value)) return '-';
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // Efeitos colaterais
  useEffect(() => {
    fetchDadosIniciais();
    fetchPrecos();
  }, []);

  // Função para buscar dados iniciais
  const fetchDadosIniciais = async () => {
    try {
      setCarregando(true);
      const token = await getToken();
      const headers = { headers: { 'x-api-key': token } };

      const [
        { data: dolarData },
        { data: materiasData },
        { data: cidadesData },
        { data: empresasFontesData },
        { data: portosData },
        { data: associadosData },
      ] = await Promise.all([
        api.get('/DolarApi'),
        api_amr.get('/return_indice_materias', headers),
        api_amr.get('/return_cidades_brasil', headers),
        api_amr.get('/retorna_empresa_fonte', headers),
        api_amr.get('/return_portos', headers),
        api_amr.get('/get_associados', headers),
      ]);

      setDolar(parseFloat(dolarData));

      setMaterias(
        materiasData.map((item) => ({ value: item.id, label: item.descNome }))
      );
      setCidades(
        cidadesData.map((item) => ({
          value: item.id,
          label: `${item.cidade}/${item.UF}`,
        }))
      );
      setEmpresas(
        empresasFontesData.empresas.map((item) => ({
          value: item.empresa,
          label: item.empresa,
        }))
      );
      setFontes(
        empresasFontesData.fontes.map((item) => ({
          value: item.fonte_dados,
          label: item.fonte_dados,
        }))
      );
      setPortos(
        portosData.map((item) => ({ value: item.id, label: item.nome_porto }))
      );
      setAssociados(
        associadosData.map((item) => ({
          value: item.id_associado,
          label: item.name_associado,
        }))
      );

      fetchNomesMaterias();
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      alert('Ocorreu um erro ao buscar os dados.');
    } finally {
      setCarregando(false);
    }
  };

  const fetchNomesMaterias = async () => {
    try {
      const token = await getToken();
      const { data } = await api_amr.get('/get_nomes_materias', {
        headers: { 'x-api-key': token },
      });
      setNomesMaterias(data);
    } catch (error) {
      console.error('Erro ao buscar nomes de matérias:', error);
    }
  };

  const fetchPrecos = async () => {
    try {
      const token = await getToken();
      const { data } = await api_amr.get('/return_precos_registrados', {
        headers: { 'x-api-key': token },
      });
      setPrecos(data);
    } catch (error) {
      console.error('Erro ao buscar preços:', error);
    }
  };

  const handleAdicionaPreco = async (e) => {
    e.preventDefault();

    if (
      !materiaSelecionada ||
      !cidadeSelecionada ||
      !empresaSelecionada ||
      !portoSelecionado ||
      !embalagem ||
      !precoCompra ||
      (isAssociado && !associado)
    ) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setAtualizar(true);

    try {
      const token = await getToken();
      const headers = { headers: { 'x-api-key': token } };

      const response = await api_amr.post(
        '/registra_preco_venda',
        {
          data,
          data_pagamento: dataPagamento,
          empresa: empresaSelecionada,
          id_porto: portoSelecionado.value,
          origem: cidadeSelecionada.value,
          destino: cidadeSelecionada.value,
          produto: materiaSelecionada,
          fonte_dados: fonteSelecionada,
          frete,
          preco_compra: precoCompra,
          dolar,
          embalagem: embalagem.value,
          volume,
          juros_brl: jurosBRL,
          juros_usd: jurosUSD,
          isAssociado,
          associado,
        },
        headers
      );

      if (response.data === 'OK') {
        alert('Preço registrado com sucesso');
        fetchPrecos();
      } else {
        alert('Ocorreu um erro, tente novamente');
      }
    } catch (error) {
      console.error('Erro ao registrar preço:', error);
      alert('Ocorreu um erro ao registrar o preço.');
    } finally {
      setAtualizar(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getToken();
      const headers = { headers: { 'x-api-key': token } };

      const response = await api_amr.post(
        '/delete_preco_registrado',
        { id_preco: id },
        headers
      );

      if (response.data === 'OK') {
        alert('Deletado com sucesso');
        fetchPrecos();
      } else {
        alert('Ocorreu um erro, tente novamente');
      }
    } catch (error) {
      console.error('Erro ao deletar preço:', error);
      alert('Ocorreu um erro ao deletar o preço.');
    }
  };

  const filteredData = precos.filter((row) =>
    row.empresa.toLowerCase().includes(filter.toLowerCase()) ||
    row.produto_abreviacao.toLowerCase().includes(filter.toLowerCase())
  );

  const adjustDateToLocal = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return localDate.toISOString().split('T')[0];
  };

  const handleNomeMateriaChange = (event, value) => {
    setMateriaSelecionada(value);
  };

  const handleNomeMateriaInputChange = (event, value) => {
    setMateriaSelecionada({ nome_materia: value });
  };

  return (
    <>
      <form onSubmit={handleAdicionaPreco}>
        {carregando && <CircularProgress />}
        <Grid container spacing={2}>
          {/* Matéria */}
          <Grid item xs={12} sm={6} md={2}>
            {escolhaMaterias ? (
              <Autocomplete
                options={materias}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => setMateriaSelecionada(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Matéria" variant="outlined" fullWidth />
                )}
              />
            ) : (
              <Autocomplete
                options={nomesMaterias}
                getOptionLabel={(option) => option.nome_materia || ''}
                onInputChange={handleNomeMateriaInputChange}
                onChange={handleNomeMateriaChange}
                renderInput={(params) => (
                  <TextField {...params} label="Matéria" variant="outlined" fullWidth />
                )}
              />
            )}
          </Grid>

          {/* Embalagem */}
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={embalagens}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setEmbalagem(value)}
              renderInput={(params) => (
                <TextField {...params} label="Embalagem" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          {/* Porto */}
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={portos}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setPortoSelecionado(value)}
              renderInput={(params) => (
                <TextField {...params} label="Porto" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          {/* Cidade */}
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={cidades}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setCidadeSelecionada(value)}
              renderInput={(params) => (
                <TextField {...params} label="Cidade" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          {/* Empresa */}
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={empresas}
              getOptionLabel={(option) => option.label || ''}
              freeSolo
              onInputChange={(e, newInputValue) => {
                setEmpresaSelecionada(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Empresa" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          {/* Associado ou Fonte */}
          {isAssociado ? (
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={associados}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => setAssociado(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Associado" variant="outlined" fullWidth />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={fontes}
                getOptionLabel={(option) => option.label || ''}
                freeSolo
                onInputChange={(e, newInputValue) => {
                  setFonteSelecionada(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Fonte" variant="outlined" fullWidth />
                )}
              />
            </Grid>
          )}

          {/* Volume */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Volume TON"
              type="number"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Frete */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Frete R$"
              type="number"
              value={frete}
              onChange={(e) => setFrete(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Preço Compra */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Preço Compra USD"
              type="number"
              value={precoCompra}
              onChange={(e) => setPrecoCompra(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Dólar */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Dólar"
              type="number"
              value={dolar}
              onChange={(e) => setDolar(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Data */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Data"
              type="date"
              value={data}
              onChange={(e) => setData(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          {/* Data de Pagamento */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Data de Pagamento"
              type="date"
              value={dataPagamento}
              onChange={(e) => setDataPagamento(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          {/* Juros BRL */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Juros BRL"
              type="number"
              value={jurosBRL}
              onChange={(e) => setJurosBRL(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Juros USD */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Juros USD"
              type="number"
              value={jurosUSD}
              onChange={(e) => setJurosUSD(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Checkbox Associado */}
          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAssociado}
                  onChange={(e) => setIsAssociado(e.target.checked)}
                />
              }
              label="Associado"
            />
          </Grid>

          {/* Checkbox Escolha Materias */}
          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={escolhaMaterias}
                  onChange={(e) => setEscolhaMaterias(e.target.checked)}
                />
              }
              label="Escolha Matérias"
            />
          </Grid>

          {/* Botão Salvar */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={atualizar}
              fullWidth
            >
              {atualizar ? <CircularProgress size={24} /> : 'Salvar'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Campo de Filtro */}
      <TextField
        label="Filtrar por Empresa ou Produto"
        fullWidth
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
      />

      {/* Tabela de Preços */}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Tabela de Preços Registrados">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Data Registro</TableCell>
              <TableCell className={classes.headerCell}>Empresa</TableCell>
              <TableCell className={classes.headerCell}>Origem</TableCell>
              <TableCell className={classes.headerCell}>Destino</TableCell>
              <TableCell className={classes.headerCell}>Volume (T)</TableCell>
              <TableCell className={classes.headerCell}>Fonte</TableCell>
              <TableCell className={classes.headerCell}>Produto</TableCell>
              <TableCell className={classes.headerCell}>Embalagem</TableCell>
              <TableCell className={classes.headerCell}>Preço Pago ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>CFR Equiv. ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>CFR Plataforma ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>Dólar</TableCell>
              <TableCell className={classes.headerCell}>Margem ($/R$)</TableCell>
              <TableCell className={classes.headerCell}>Data Pagamento</TableCell>
              <TableCell className={classes.headerCell}>Juros</TableCell>
              <TableCell className={classes.headerCell}>Deletar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => {
              const margemUSD = parseFloat(row.margem);
              const margemBRL = margemUSD * parseFloat(dolar);

              // Limitar margem entre -100 e 100
              const cappedMargemUSD = Math.max(-100, Math.min(100, margemUSD));

              // Calcular o matiz correspondente
              const hue = ((-cappedMargemUSD + 100) / 200) * 120;

              // Definir cor de fundo usando HSL
              const margemBackgroundColor = `hsl(${hue}, 50%, 80%)`;

              return (

                <TableRow key={row.id}>
                  <TableCell className={classes.headerCell}>{adjustDateToLocal(row.data)}</TableCell>
                  <TableCell className={classes.headerCell}>{row.empresa}</TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.origem_cidade}-{row.origem_UF}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.destino_cidade}-{row.destino_UF}
                  </TableCell>
                  <TableCell className={classes.headerCell}>{row.volume}</TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.fonte_dados !== 'None' ? row.fonte_dados : row.name_associado}
                  </TableCell>
                  <TableCell className={classes.headerCell}>{row.produto_abreviacao}</TableCell>
                  <TableCell className={classes.headerCell}>{row.embalagem}</TableCell>
                  {/* Preço Pago ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.preco_compra ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.preco_compra), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          ({formatCurrency(parseFloat(row.preco_compra) * parseFloat(row.dolar), 'BRL')})
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* CFR Equiv. ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.cfr_eqv ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.cfr_eqv), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          ({formatCurrency(parseFloat(row.cfr_eqv) * parseFloat(row.dolar), 'BRL')})
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* CFR Plataforma ($/R$) */}
                  <TableCell className={classes.headerCell}>
                    {row.cfr_semana ? (
                      <>
                        <strong>{formatCurrency(parseFloat(row.cfr_semana), 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          ({formatCurrency(parseFloat(row.cfr_semana) * parseFloat(row.dolar), 'BRL')})
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* Dólar */}
                  <TableCell className={classes.headerCell}>
                    {row.dolar ? (
                      <strong>{formatCurrency(parseFloat(row.dolar), 'BRL')}</strong>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {/* Margem ($/R$) */}
                  <TableCell
                    className={classes.headerCell}
                    style={{ backgroundColor: margemBackgroundColor }}
                  >
                    {row.margem ? (
                      <>
                        <strong>{formatCurrency(margemUSD, 'USD')}</strong>
                        <br />
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                          ({formatCurrency(margemBRL, 'BRL')})
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.data_pagamento !== 'None' ? adjustDateToLocal(row.data_pagamento) : ''}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    {row.juros_usd !== -1 || row.juros_brl !== -1 ? (
                      <>
                        {row.juros_usd !== -1 && (
                          <>
                            <strong>USD: {row.juros_usd}%</strong>
                            <br />
                          </>
                        )}
                        {row.juros_brl !== -1 && (
                          <>
                            <strong>BRL: {row.juros_brl}%</strong>
                          </>
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.headerCell}>
                    <Button onClick={() => handleDelete(row.id)}>Deletar</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RegistradorPrecos;
