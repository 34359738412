import React, { useState, useEffect } from 'react';
import api from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    Collapse,
    IconButton,
    CircularProgress,
    Grid,
    Button,
    Switch,
    FormControlLabel,
    TextField
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        maxWidth: '800px',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    groupRow: {
        backgroundColor: theme.palette.grey[200],
        fontWeight: 'bold',
        padding: '4px 8px',
    },
    summaryRow: {
        backgroundColor: theme.palette.grey[300],
        fontWeight: 'bold',
        padding: '4px 8px',
    },
    nestedTableCell: {
        paddingLeft: theme.spacing(2),
        padding: '2px 4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    nestedTableHeader: {
        backgroundColor: theme.palette.grey[100],
        fontWeight: 'bold',
        padding: '2px 4px',
    },
    iconButton: {
        padding: '0 4px',
    },
    tableCell: {
        padding: '2px 4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    filterContainer: {
        marginBottom: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        textAlign: 'right',
    },
}));

const formatNumber = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 0, 
        maximumFractionDigits: 2
    }).format(value);
};

const statusOptions = ['TODOS', 'Aberto', 'Fechado'];

const SumarizacaoDemandas = () => {
    const classes = useStyles();
    const [demandas, setDemandas] = useState([]);
    const [openRows, setOpenRows] = useState({});
    const [status, setStatus] = useState('TODOS');
    const [culturas, setCulturas] = useState([]);
    const [anosSafra, setAnosSafra] = useState([]);
    const [selectedCultura, setSelectedCultura] = useState(null);
    const [selectedAnoSafra, setSelectedAnoSafra] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('associados'); // 'associados' or 'produtos'

    useEffect(() => {
        const fetchCulturas = async () => {
            try {
                const response = await api.get('/get_culturas', {
                    headers: { 'x-api-key': getToken() },
                });
                setCulturas(response.data);
            } catch (error) {
                console.error("Erro ao buscar culturas", error);
                setError("Erro ao buscar culturas");
            }
        };

        const fetchAnosSafra = async () => {
            try {
                const response = await api.get('/read_anos_safra', {
                    headers: { 'x-api-key': getToken() },
                });
                setAnosSafra(response.data);
            } catch (error) {
                console.error("Erro ao buscar anos safra", error);
                setError("Erro ao buscar anos safra");
            }
        };

        fetchCulturas();
        fetchAnosSafra();
    }, []);

    const fetchDemandas = async () => {
        if (!status || !selectedCultura || !selectedAnoSafra) {
            setError('Por favor, preencha todos os campos.');
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await api.get('/get_sumarizacao_demandas', {
                headers: {
                    'x-api-key': getToken(),
                },
                params: {
                    status: status,
                    cultura: selectedCultura ? selectedCultura.id_cultura : null,
                    ano_safra: selectedAnoSafra ? selectedAnoSafra.id_ano_safra : null,
                    viewMode: viewMode,
                },
            });

            if (response.data === 'NOK') {
                throw new Error("Erro ao buscar demandas sumarizadas");
            }

            setDemandas(response.data);
        } catch (error) {
            console.error(error);
            setError("Erro ao buscar demandas sumarizadas");
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (key) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const isButtonDisabled = !status || !selectedCultura || !selectedAnoSafra;

    return (
        <Container className={classes.container}>
            <Typography variant="h5" component="h1" className={classes.title}>
                Sumarização das Demandas
            </Typography>

            {error && <Alert severity="error">{error}</Alert>}

            <Grid container spacing={2} className={classes.filterContainer}>
                <Grid item xs={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={statusOptions}
                        value={status}
                        onChange={(event, newValue) => setStatus(newValue)}
                        renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={culturas}
                        getOptionLabel={(option) => option.nome_cultura}
                        value={selectedCultura}
                        onChange={(event, newValue) => setSelectedCultura(newValue)}
                        renderInput={(params) => <TextField {...params} label="Cultura" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={anosSafra}
                        getOptionLabel={(option) => option.descricao}
                        value={selectedAnoSafra}
                        onChange={(event, newValue) => setSelectedAnoSafra(newValue)}
                        renderInput={(params) => <TextField {...params} label="Ano Safra" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={viewMode === 'produtos'}
                                onChange={(event) => (setDemandas([]),setViewMode(event.target.checked ? 'produtos' : 'associados'))}
                                color="primary"
                            />
                        }
                        label={viewMode === 'produtos' ? 'Produtos' : 'Associados'}
                    />
                </Grid>
            </Grid>

            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchDemandas}
                    disabled={isButtonDisabled}
                >
                    Buscar Demandas
                </Button>
            </div>

            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    {viewMode === 'produtos' ? 'Produto' : 'Associado'}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    Volume (Ton)
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {demandas.map((firstLevel, index) => (
                                <React.Fragment key={index}>
                                    <TableRow className={classes.groupRow}>
                                        <TableCell className={classes.tableCell}>
                                            {viewMode === 'produtos' ? firstLevel.produto : firstLevel.associado}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="right">
                                            {formatNumber(firstLevel.total)}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => handleRowClick(index)}
                                            >
                                                {openRows[index] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                            <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                                <Table size="small">
                                                    <TableHead className={classes.nestedTableHeader}>
                                                        <TableRow>
                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(viewMode === 'produtos' ? firstLevel.associados : firstLevel.users).map((secondLevel, secondIndex) => (
                                                            <React.Fragment key={secondIndex}>
                                                                <TableRow className={classes.groupRow}>
                                                                    <TableCell className={classes.nestedTableCell}>
                                                                        {viewMode === 'produtos' ? secondLevel.associado : secondLevel.user}
                                                                    </TableCell>
                                                                    <TableCell className={classes.nestedTableCell} align="right">
                                                                        {formatNumber(secondLevel.total)}
                                                                    </TableCell>
                                                                    <TableCell className={classes.nestedTableCell} align="center">
                                                                        <IconButton
                                                                            className={classes.iconButton}
                                                                            onClick={() => handleRowClick(`${index}-${secondIndex}`)}
                                                                        >
                                                                            {openRows[`${index}-${secondIndex}`] ? <ExpandLess /> : <ExpandMore />}
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                                                        <Collapse in={openRows[`${index}-${secondIndex}`]} timeout="auto" unmountOnExit>
                                                                            <Table size="small">
                                                                                <TableHead className={classes.nestedTableHeader}>
                                                                                    <TableRow>
                                                                                        <TableCell className={classes.nestedTableCell}>
                                                                                            {viewMode === 'produtos' ? 'Usuário' : 'Produto'}
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.nestedTableCell} align="right">
                                                                                            Volume (Ton)
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {(viewMode === 'produtos' ? secondLevel.users : secondLevel.produtos).map((thirdLevel, thirdIndex) => (
                                                                                        <TableRow key={thirdIndex}>
                                                                                            <TableCell className={classes.nestedTableCell}>
                                                                                                {viewMode === 'produtos' ? thirdLevel.user : thirdLevel.produto}
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.nestedTableCell} align="right">
                                                                                                {formatNumber(thirdLevel.total)}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                            <TableRow className={classes.summaryRow}>
                                <TableCell className={classes.tableCell}>Volume Geral (Ton)</TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                    {formatNumber(demandas.reduce((acc, group) => acc + group.total, 0))}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default SumarizacaoDemandas;
